body {
  font-family: "Montserrat", sans-serif !important;
}

/* LOGIN */

.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login {
  /* min-height: 80vh; */
  display: flex;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.6);
  margin: 50px;
  border-radius: 10px;
}

.login-second {
  background: #dadddd;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login h3 {
  font-size: 21px;
  font-weight: 600;
}

.login-first p {
  font-size: 15px;
  font-weight: 500;
}

.login-first,
.login-second {
  width: 50%;
  padding: 20px;
  text-align: center;
}

.login-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
}

.toggle_Password {
  position: relative;
}

.toggle_Password svg {
  position: absolute;
  right: 10px;
  top: 45px;
  cursor: pointer;
}

.otp_Logo {
  width: 150px;
  margin-bottom: 20px;
}

.otp_Logo img {
  width: 100%;
}

img.login-dog-image {
  width: 150px;
  margin-bottom: 20px;
}

img.confused-dog-image {
  width: 250px;
  margin-bottom: 20px;
}

img.login-logo {
  width: 100px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 0;
}

.password-login-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.login-second label.form-label {
  font-size: 15px;
  font-weight: 500;
}

.login-second form {
  text-align: initial;
}

span.star-required {
  color: red;
  font-size: 17px;
}

a.forgot-password-link {
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.login-second p {
  font-weight: 500;
}

.login-second button.login-button {
  background: #ac5f5a;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  width: 200px;
  border: none;
  border-radius: 10px;
  margin: auto;
}

p.dont-acc.text-center {
  font-size: 15px;
  font-weight: 500;
}

a.signup-text {
  color: #495f75;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

p.error-p {
  min-height: 20px;
  margin: 5px 0;
}

.login-second .mb-3 {
  margin-bottom: 10px !important;
}

/* ////////////////VERIFY OTP/////////////////////// */
.login_Second_Image img {
  width: 100px;
  margin-bottom: 20px;
}

.login_Second_Image {
  margin-bottom: 20px;
}

.login_Second_Image P {
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}

.otp-section input {
  border-radius: 5px;
  border: none;
}

.otp-section {
  justify-content: center;
  margin-bottom: 20px;
}

p.forgot-texthover {
  text-decoration: underline;
}

p.forgot-text {
  font-size: 16px;
  font-weight: 600;
  color: #436077;
  width: fit-content;
}

a.resendotp-section:hover {
  text-decoration: underline;
}

.verify_OTP_Row.row p.back-arrow.dashboard {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

a.resendotp-section {
  text-decoration: none;
  transition: 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  width: 120px;
  margin: auto;
  color: #436077;
  height: 25px;
}

/* ////////////////FORGOT PASSWORD/////////////////////// */
.login-second i {
  font-size: 14px;
}

.forgot_Password_Main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

form.resend_OTP_Form button {
  background: #ac5f5a;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  width: 200px;
  border: none;
  border-radius: 10px;
  margin: auto;
}

button.login-button {
  background: #ac5f5a;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  width: 200px;
  border: none;
  border-radius: 10px;
  margin: auto;
}

.Forgot_Password {
  display: flex;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.6);
  margin: 50px;
  border-radius: 10px;
  /* min-height: 80vh; */
}

.forgot-password-one p {
  font-size: 15px;
  font-weight: 500;
}

.forgot-password-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
}

.Forgot_Password h3 {
  font-size: 21px;
  font-weight: 600;
}

.forgot-password-two {
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #dadddd;
}

.forgot-password-one p {
  font-size: 15px;
  font-weight: 500;
}

.forgot-password-one,
.forgot-password-two {
  width: 50%;
  padding: 20px;
  text-align: center;
}

.forgot-password-two label.form-label {
  font-size: 15px;
  font-weight: 500;
}

.forgot-password-two form {
  text-align: initial;
}

.password-login-div p.back-arrow.dashboard {
  justify-content: center;
}

.forgot_Password_Head p {
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
}

button#button-addon2:hover {
  background: transparent;
  color: #495f75;
}

button#button-addon2 {
  position: absolute;
  right: 10px;
  top: 34px;
  border: none;
  color: #495f75;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #495f75 !important;
}

.loginwith:after,
.loginwith:before {
  background-color: #495f75;
  content: "";
  display: inline-block;
  height: 1px;
  opacity: 0.5;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.loginwith {
  margin-bottom: 25px !important;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
}

.loginwith:before {
  margin-left: -50%;
  right: 0.5em;
}

.loginwith:after {
  left: 0.5em;
  margin-right: -50%;
}

/* MODALS */
img.closeButton {
  width: 15px !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-body {
  padding: 40px;
}

.fade.modal .modal-buttons button {
  min-width: 100px;
}

.fade.modal .modal-buttons {
  justify-content: center;
  display: flex;
  gap: 10px;
}

.fade.modal .modal-body p {
  font-size: 16px;
  font-weight: 600;
}

/* .fade.modal .modal-body img {
  width: 100%;
} */
.modal_Head_Image {
  width: 100%;
}

.fade.modal .modal-body {
  text-align: center;
}

.fade.modal .modal-buttons button:first-child {
  background: transparent;
  color: black;
  border: 1px solid black;
  font-size: 16px;
  font-weight: 600;
}

.fade.modal .modal-buttons button:first-child {
  background: transparent;
  color: #436077;
  border: 1px solid #436077;
  font-size: 16px;
  font-weight: 600;
}

.fade.modal .modal-buttons button:last-child {
  background: #436077;
  color: white;
  border: 1px solid #436077;
  font-size: 16px;
  font-weight: 600;
}

/* CREATE BUTTON */
button.create {
  background: #ac5f5a;
  color: white;
  font-size: 12px;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  min-width: 130px;
  font-weight: 600;
}
button.create.health_Activity {
  min-width: 185px;
}
/* PAGINATION */
.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 20px;
  transition: 0.2s ease;
  text-decoration: none !important;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
}

.paginationBttns a {
  padding: 6px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #436077 !important;
  text-align: center;
  letter-spacing: 0.562197px;
  cursor: pointer;
  border: 2px solid #436077;
  transition: 0.2s ease;
  text-decoration: none !important;
}

.paginationBttns a:hover {
  color: white !important;
  background: #436077;
  transition: 0.2s ease;
}

.paginationActive a {
  color: white !important;
  background: #436077;
  transition: 0.2s ease;
  /* border: 1px solid orange; */
}

/* TABLE */
th.tablehead:first-child {
  border-top-left-radius: 10px;
}

th.tablehead:last-child {
  border-top-right-radius: 10px;
}

.tableheaders {
  display: flex;
  justify-content: initial;
  align-items: center;
}

th.tablehead:last-child .tableheaders {
  justify-content: center;
}

td.TableButtons {
  text-align: center;
}

.SortButton {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SortButton img {
  width: 10px;
  margin-left: 5px;
  filter: invert(1);
  cursor: pointer;
}

.SortButton button.btn.btn-primary {
  background: transparent;
  border: none;
  padding: 0px;
  max-height: 10px;
}

button.ant-switch,
:where(.css-dev-only-do-not-override-17a39f8).ant-switch:hover:not(
    .ant-switch-disabled
  ) {
  background-color: #ee5858;
}

button.ant-switch.ant-switch-checked,
:where(
    .css-dev-only-do-not-override-17a39f8
  ).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #66c866;
}

.center_Col {
  justify-content: center;
  display: flex;
}

.TableMain {
  margin-top: 10px;
  padding: 7px;
  overflow-x: auto;
}

table {
  width: 100%;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.16), 0 0px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
}

thead {
  background: #436077;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #436077;
}

th {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

td {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

td.breed-name {
  text-align: initial;
}

.table-action img {
  width: 20px;
  cursor: pointer;
}

.table-action {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* BREED TABLE */
.breeds-Main th:first-child,
.breeds-Main td:first-child,
.colors-Main th:first-child,
.colors-Main td:first-child,
.Personality-Main th:first-child,
.Personality-Main td:first-child,
.toys-Main th:first-child,
.toys-Main td:first-child,
.food-Main th:first-child,
.food-Main td:first-child,
.Activities-Main th:first-child,
.Activities-Main td:first-child,
.User-Main th:nth-child(3),
.User-Main td:nth-child(3),
.Admin-user-Main th:nth-child(2),
.Admin-user-Main td:nth-child(2) {
  min-width: 300px;
  max-width: 300px;
  overflow-wrap: break-word;
}

.User-Main th:nth-child(6),
.User-Main td:nth-child(6) {
  min-width: 200px;
  max-width: 200px;
  overflow-wrap: break-word;
}

.User-Main th:first-child,
.User-Main td:first-child,
.User-Main th:nth-child(2),
.User-Main td:nth-child(2),
.User-Main th:nth-child(4),
.User-Main td:nth-child(4),
.User-Main th:nth-child(5),
.User-Main td:nth-child(5),
.User-Main th:nth-child(7),
.User-Main td:nth-child(7),
.User-Main th:nth-child(8),
.User-Main td:nth-child(8),
.User-Main th:nth-child(9),
.User-Main td:nth-child(9),
.Admin-user-Main th:nth-child(3),
.Admin-user-Main td:nth-child(3),
.Admin-user-Main th:nth-child(4),
.Admin-user-Main td:nth-child(4),
.Admin-user-Main th:first-child,
.Admin-user-Main td:first-child {
  min-width: 150px;
  max-width: 150px;
  overflow-wrap: break-word;
}

.Personality-Main th:nth-child(2),
.Personality-Main td:nth-child(2),
.Activities-Mainth:nth-child(2),
.Activities-Maintd:nth-child(2) {
  min-width: 100px;
  max-width: 100px;
}

/* MODAL */
.modal-body {
  border: none !important;
}

.modal-image {
  width: 100px;
  margin: auto;
}

.modal-image img {
  width: 100%;
  margin: 0 0 20px;
}

.modal-body h3 {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.modal-body h4 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.Modal-input {
  background: #f5f5f5;
  border-radius: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.Modal-input input {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 0;
  background: transparent;
  padding: 10px;
}

button.modal_Button,
button.modal_Button:hover,
form.resend_OTP_Form button.modal_Button,
form.resend_OTP_Form button.modal_Button:hover {
  min-width: 100px;
  border-radius: 10px;
  background: #436077;
  border: 0;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  padding: 10px 20px;
}

form.resend_OTP_Form {
  text-align: center;
}

.resend_OTP_Form > div[data-testid="otp-resend-root"] {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px !important;
}

.crop-btn {
  position: absolute;
  overflow: visible;
  visibility: visible;
  left: 50%;
  transform: translateX(-50%);
  background: #436077;
  z-index: 2;
  padding: 10px 20px;
  border-radius: 10px;
  bottom: 0 !important;
  color: white !important;
  transition: 0.2s ease-in-out;
  min-width: 100px;
}

.crop-btn:hover {
  color: #436077 !important;
  background-color: white;
}

label._coverImage-holder {
  border-radius: 20px;
  /* padding: 5px 10px; */
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 5px #888888;
  cursor: pointer;
  position: relative;
  width: 100%;
}

header.App-header h5 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.cropped-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  position: relative;
  width: fit-content;
  margin: auto;
}

img.modal_Image_Close {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

header.App-header {
  width: 30%;
  margin: 20px auto 20px;
}

/* .cropped-image-container button {
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  background: #436077;
  border: 0;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
} */

label.hide_CoverImage_Holder {
  display: none;
}

img.edit_Icon {
  position: absolute;
  width: 20px;
  right: 10px;
  top: 10px;
}

/* BACK ARROW */
p.back-arrow.dashboard {
  display: flex;
  justify-content: initial;
  margin: 0;
}

p.back-arrow a {
  color: #000;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

p.back-arrow a:hover {
  color: #436077;
  cursor: pointer;
}

svg.arrow-left {
  font-size: 25px;
}

/* SEARCH */
.ResultBar {
  min-height: 25px;
  margin-top: 10px;
}

p.searchBarResult {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

span.searchResults {
  color: #436077;
}

.search-modal {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.Search {
  width: 70%;
  background: #f6f6f6;
  border-radius: 10px;
  display: flex;
  max-height: 40px;
  padding-left: 5px;
  justify-content: space-between;
}
.message_Tabs .Search {
  width: calc(100% - 185px);
}
.Search > div {
  width: 85%;
  display: flex;
  padding: 10px 5px;
}

.Search img {
  width: 20px;
}

.Search input {
  background: #f6f6f6;
  border: 0;
  padding-left: 5px;
  padding-right: 10px;
  width: 90%;
}

.Search input:focus-visible {
  outline: none;
}

.Search button {
  background: #ac5f5a;
  color: white;
  border: 0;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
}

/* NAVBAR */
.Layout {
  position: fixed;
  width: 100vw;
}

button.logout-btn {
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background: #ac5f5a;
  color: white;
  display: flex;
  gap: 10px;
  margin-right: 12px;
}

button.logout-btn img {
  width: 15px;
  margin: auto;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark .container {
  height: 80%;
}

div#responsive-navbar-nav {
  flex-direction: column;
  justify-content: space-between;
  height: 95%;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  width: 240px;
  padding: 0;
  padding-top: 20px;
  display: flex;
  align-items: baseline;
  height: 100vh;
  position: fixed;
  left: 0;
  background: #dadddd !important;
}

nav.navbar.navbar-expand-lg.navbar-dark.bg-dark .container {
  height: calc(100% - 70px);
  display: block !important;
  padding-right: 0;
}

img.logoImage {
  width: 50px;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.me-auto.Navtabs.navbar-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #436077;
  border-radius: 10px;
}

div#basic-navbar-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
}

a.navbar-brand {
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #436077;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

a.navbar-brand:hover {
  color: #436077;
}

.me-auto.navbar-nav a.active {
  background: #436077;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.me-auto.navbar-nav a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin: 2px 0;
  border-radius: 35px 0 0 35px;
  transition: ease-in-out 0.1s;
  text-align: initial;
}
a.messages_Tab {
  margin-bottom: 0 !important;
}
a.inactive:hover {
  background: #436077;
  color: white;
  font-weight: 600;
}

.me-auto.Navtabs.navbar-nav img {
  width: 35px;
  margin-right: 10px;
}

/* DASHBOARD */
.dashboard-Main {
  padding: 20px;
  padding-left: 250px;
}

.Card {
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  width: 100%;
  justify-content: initial;
}

.DashboardCard .col-lg-3.col-md-6.col-sm-6.col-12 {
  display: flex;
  min-height: 100px;
  padding: 0;
  width: calc(25% - 10px);
}

.DashboardCard .row {
  gap: 10px;
  width: 100%;
  margin: 0;
}
.DashboardCard .row:first-child {
  padding-left: 10px;
}
.CardDiv {
  max-width: 30%;
}

.CardDetails {
  max-width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p.title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: initial;
}

p.value {
  text-align: initial;
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  overflow-wrap: anywhere;
  line-height: 25px;
}

img.cardImage {
  padding-right: 10px;
  width: 50px;
}

/* LOGS */
.logs_Main {
  margin-top: 20px !important;
}

.log-Time {
  width: 130px;
}

.logs_Div {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.log_Image_Details {
  display: flex;
  gap: 20px;
  align-items: initial;
  width: calc(100% - 200px);
}
.message_Edit_Image {
  width: 30px;
}
.message_Edit_Image img {
  width: 80%;
  cursor: pointer;
}
.log_Details {
  width: calc(100% - 100px);
}

.log-Time p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}

.load_More button {
  background: #436077;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  min-width: 150px;
}

.load_More svg {
  padding-left: 5px;
}

.load_More {
  text-align: center;
}

.log_Details p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.logs_Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.log_Image img {
  width: 50px;
}

.log_Image {
  width: 50px;
}

/* BREEDS */
.breeds-Main {
  padding: 20px;
  padding-left: 250px;
}

.breeds-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.breeds-Div {
  padding-left: 7px;
}

/* COLORS */
.colors-Main {
  padding: 20px;
  padding-left: 250px;
}

.colors-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.colors-Div {
  padding-left: 7px;
}

/* PERSONALITY */
.Personality-Main {
  padding: 20px;
  padding-left: 250px;
}

.Personality-Div {
  padding-left: 7px;
}

.Personality-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

img.personality-img-table {
  width: 50px;
}

/* TOYS */
.toys-Main {
  padding: 20px;
  padding-left: 250px;
}

.toys-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.toys-Div {
  padding-left: 7px;
}

/* FOOD */
.food-Main {
  padding: 20px;
  padding-left: 250px;
}

.food-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.food-Div {
  padding-left: 7px;
}

/* ACTIVITIES */
.Activities-Main {
  padding: 20px;
  padding-left: 250px;
}

.Activities-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.Activities-Div {
  padding-left: 7px;
}

/* USERS */
.User-Main {
  padding: 20px;
  padding-left: 250px;
}

.User-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.User-Div {
  padding-left: 7px;
}

td.NoDataAvailable p,
p.NoDataAvailable {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

td.NoDataAvailable {
  text-align: center;
  padding: 10px;
}

td.email_Links svg {
  width: 50px;
  height: 50px;
  fill: #436077;
  cursor: pointer;
}

td.email_Links {
  text-align: center;
}

.email_Modal .modal-header {
  padding: 0;
  border: none;
  margin-bottom: 20px;
}

.email_Modal_Table th:first-child,
.email_Modal_Table td:first-child {
  text-align: initial;
  min-width: 300px;
  max-width: 300px;
  overflow-wrap: break-word;
}

.email_Modal_Table th:nth-child(2),
.email_Modal_Table td:nth-child(2),
.email_Modal_Table th:nth-child(3),
.email_Modal_Table td:nth-child(3),
.email_Modal_Table th:nth-child(4),
.email_Modal_Table td:nth-child(4) {
  min-width: 170px;
  max-width: 170px;
  overflow-wrap: break-word;
}

.email_Modal_Table th:nth-child(5),
.email_Modal_Table td:nth-child(5) {
  min-width: 130px;
  max-width: 130px;
}

.email_Modal_Table tbody p {
  font-weight: 500 !important;
}

.email_Modal_Table {
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0 0 5px #00000069;
}

.email_Modal_Table p {
  margin: 0;
  padding: 10px 0;
}

.email_Modal .modal-header p {
  font-size: 20px !important;
  margin: 0;
}

.admin_User_Modal .mb-3 {
  text-align: initial;
}

td.action_td p {
  border-radius: 20px;
  padding: 5px;
}

p.Open {
  background: #e6f4e6;
  color: #059002;
}

p.Bounced {
  background: #fdf0e6;
  color: #e66100;
}

p.Deferred,
p.Dropped {
  background: #fae9e9;
  color: #cc1e1e;
}

p.Delivered {
  background: #e6f3f8;
  color: #0480b5;
}

p.Processed {
  background: #fff6e6;
  color: #ff9e00;
}

p.subject_td {
  text-decoration: underline;
  cursor: pointer;
  color: #1a1a88;
}

.email_Details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.email_Details > div {
  width: 50%;
  text-align: initial;
}

.email_Details > div {
  width: calc(50% - 5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.email_Details .email_Content,
.email_Details .subject {
  width: 100%;
}

.email_Details p {
  font-weight: 500 !important;
  margin: 0;
  padding: 10px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 5px;
}

button.resend_Button {
  background: #436077;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 20px;
}

p.no_Data_Available {
  text-align: center;
  margin: 0;
}

/* NOTIFICATIONS */
.Notification-Main {
  padding: 20px;
  padding-left: 250px;
}

.Notification-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.Notification-Div {
  padding-left: 7px;
}

/* ADMIN USERS */
.Admin-user-Main {
  padding: 20px;
  padding-left: 250px;
}

.Admin-user-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.Admin-user-Div {
  padding-left: 7px;
}

/* ADMIN USERS */
.All-Logs-Main {
  padding: 20px;
  padding-left: 250px;
}

.All-Logs-Main h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: initial;
  margin-top: 10px;
}

.All-Logs-Div {
  padding-left: 7px;
}

.All-Logs-Main .search-modal {
  justify-content: end;
}

/* COMMON */
.breeds-Div,
.Activities-Div,
.colors-Div,
.food-Div,
.Personality-Div,
.toys-Div,
.User,
.Notification-Div,
.Admin-user-Div,
.All-Logs-Div {
  padding: 7px;
}

.error-msg {
  text-align: left;
  color: #dc3545;
}

.position-relative {
  position: relative;
}

.react-select-3-listbox {
  z-index: 99;
}

label {
  font-size: 16px;
  font-weight: 500;
}

.label_Input {
  margin-bottom: 20px;
}

input.subject_Input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
}

input.subject_Input:focus-visible {
  outline: 2px solid #3d90fd;
}

.select_Comp {
  position: relative;
  z-index: 99;
}

span.required_Star {
  color: red;
  margin-left: 5px;
}

.notification_Button {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.notification_Button button {
  min-width: 150px;
  padding: 10px;
}

.notification_Button button:first-child {
  border: 3px solid #436077;
  border-radius: 10px;
  background: white;
  color: #436077;
  font-size: 16px;
  font-weight: 600;
}

.notification_Button button:last-child {
  border: 3px solid #436077;
  border-radius: 10px;
  background: #436077;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.crop-btn {
  position: absolute;
  overflow: visible;
  visibility: visible;
  bottom: -38px;
}

img.cropped-image {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
}

.petimage {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px -2px;
  padding: 10px;
}

img.default_Image {
  width: 100%;
}

form.create_Admin_User .mb-3 {
  text-align: initial;
}

.err-msg {
  color: red;
  text-align: left;
  font-size: 13px !important;
}

.img-msg{
  text-align: center;
  color: red;
  font-size: 13px !important;
}

.public_Routes {
  width: 80vw;
  max-width: 1200px;
}

.otp-section input {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #000;
}

.otp-section input:focus {
  border: 1px solid #e66100;
}

.forgot-text1 {
  width: 82%;
}

/* MESSAGES */
.health_activity {
  display: flex;
  padding: 5px;
  box-shadow: 0px 0px 2px;
  width: fit-content;
  margin: auto;
  border-radius: 5px;
  background-color: #c9c9c9;
  position: relative;
}
.health_activity div {
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.toggle_Navigation {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top: 2px;
}
.active_Class {
  color: white;
}
.colored_Box {
  background: #436077;
  width: 50%;
  height: 85%;
  position: absolute !important;
  left: 5px;
  z-index: 1 !important;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 991px) {
  nav.navbar.navbar-expand-lg.navbar-dark.bg-dark {
    width: 100vw;
    height: 100px;
    padding-top: 0;
    display: flex;
  }
  .message_Tabs .Search {
    width: 100%;
  }
  button.navbar-toggler {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  button.navbar-toggler {
    border: none;
    background: #436077;
  }

  button.logout-btn {
    margin-top: 20px;
  }

  .me-auto.Navtabs.navbar-nav {
    height: calc(100% - 200px);
  }

  .navbar-collapse.collapse,
  .navbar-collapse.collapse.show {
    height: 100vh !important;
  }

  .navbar > .container {
    display: flex !important;
    padding: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .navbar-collapse {
    background: #dadddd;
    padding: 20px 0 10px 10px;
  }

  .dashboard-Main,
  .breeds-Main,
  .colors-Main,
  .Personality-Main,
  .toys-Main,
  .food-Main,
  .Activities-Main,
  .User-Main,
  .Notification-Main,
  .Admin-user-Main,
  .All-Logs-Main {
    padding: 20px;
    padding-top: 120px;
    padding-left: 13px;
  }

  .login.public_Routes {
    flex-wrap: wrap;
    width: 100vw;
    margin: 0 10px;
  }

  .login-first,
  .login-second {
    width: 100%;
  }

  .logs_Main {
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .search-modal {
    flex-direction: column;
    margin: auto;
  }

  .Search {
    width: 100%;
  }

  .login-first,
  .forgot-password-one {
    display: none;
  }

  .login-second,
  .forgot-password-two {
    height: 100vh;
  }

  .login.public_Routes,
  .public_Routes {
    width: 100vw;
    margin: 0;
  }

  .DashboardCard .col-lg-3.col-md-6.col-sm-6.col-12 {
    width: calc(50% - 10px);
  }

  .log_Image_Details {
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
  }

  .logs_Div {
    gap: 0;
  }

  .log_Image,
  .log_Details {
    width: 100%;
  }
}

@media screen and (max-width: 475px) {
  .DashboardCard .col-lg-3.col-md-6.col-sm-6.col-12 {
    width: 100%;
  }

  .notification_Button {
    flex-direction: column;
  }
}
