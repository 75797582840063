.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .loader {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    position: absolute;
  }
  .logoloader{
    width: 100px;
  }
  .splashScreen3 div{
    justify-content: center;
  }